import React from "react"
import QuoteForm from "@/components/forms/QuoteForm"
import DownloadForm from "@/components/forms/DownloadForm"
import RegistrationForm from "@/components/forms/RegistrationForm"

export default function FormSelector({ type, data, lang }) {
  const formComponents = {
    Register: RegistrationForm,
    "Custom Quote": QuoteForm,
    "File Download": DownloadForm,
  }
  const Form = formComponents[type]
  return <>{Form && <Form data={data} lang={lang} />}</>
}
