import React from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import SendButton from "@/components/forms/partials/SendButton"

export default function Form({
  children,
  onSubmit,
  schema,
  data,
  isWorking,
  ...rest
}) {
  const resolver = schema
    ? { resolver: yupResolver(schema) }
    : { resolver: false }
  const methods = useForm(resolver)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate {...rest}>
        <div className="grid grid-cols-1 gap-3">{children}</div>
        <SendButton content={data.send_button_label} isWorking={isWorking} />
      </form>
    </FormProvider>
  )
}
