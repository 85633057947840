import React, { useState, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { BsCloudUpload } from "react-icons/bs"
import { useDropzone } from "react-dropzone"
import { MdClose } from "react-icons/md"
import uniqBy from "lodash/uniqBy"

import Label from "@/components/forms/partials/Label"

export default function FileField({
  name,
  label,
  description,
  hideLabel,
  ...rest
}) {
  const { register, setValue } = useFormContext()
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 1024 ** 2 * 30,
    onDropAccepted: (newFiles) => {
      setFiles(
        uniqBy([...files, ...newFiles], (f) => {
          return f.path
        })
      )
      return newFiles
    },

    onDropRejected: (fileRejections) => {},
  })
  useEffect(() => {
    setValue(name, files)
  }, [files])

  return (
    <>
      <Label name={name} hidden={hideLabel} required={rest.required}>
        {label}
      </Label>
      <div className="border border-dashed border-light-text inline-block rounded w-full">
        <div
          {...getRootProps()}
          className="flex items-center justify-center p-4 py-10 text-center "
        >
          <input
            {...register(name)}
            name={name}
            id={name}
            {...getInputProps()}
          />
          <div className="text-center">
            <BsCloudUpload className="w-8 h-8 mx-auto mb-2 text-light-text" />
            <div className="text-sm">
              {description || "Drop your translation files or click to upload."}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-wrap"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {files.map((file) => {
          return (
            <div
              className="flex  items-center text-sm mt-1.5 mr-2 bg-light-text text-white px-2   rounded-sm"
              key={file.name}
            >
              {file.name}
              <MdClose
                className="ml-2 py-1.5 h-6 cursor-pointer"
                onClick={() => {
                  setFiles(files.filter((f) => f.path !== file.path))
                }}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

// function FileField({ field, setValue, id, trigger }) {
//   const [files, setFiles] = useState([])
//   const { getRootProps, getInputProps } = useDropzone({
//     multiple: true,
//     maxSize: 1024 ** 2 * 30,
//     onDropAccepted: (newFiles) => {
//       setFiles(
//         uniqBy([...files, ...newFiles], (f) => {
//           return f.path
//         })
//       )
//       return newFiles
//     },

//     onDropRejected: (fileRejections) => {},
//   })
//   useEffect(() => {
//     setValue(field.label, files)
//     trigger(field.label)
//   }, [files])

//   return (
//     <>
//       <div
//         className={cn(
//           "border border-dashed border-light-text inline-block rounded w-full"
//         )}
//       >
//         <div
//           {...getRootProps()}
//           className="flex items-center justify-center p-4 py-10 text-center "
//         >
//           <input id={id} name={field.label} {...getInputProps()} />
//           <div className="text-center">
//             <BsCloudUpload className="w-8 h-8 mx-auto mb-2 text-light-text" />
//             <div>Drop your translation files or click to upload.</div>
//           </div>
//         </div>
//       </div>
//       <div
//         className="flex flex-wrap"
//         onClick={(e) => {
//           e.stopPropagation()
//         }}
//       >
//         {files.map((file) => {
//           return (
//             <div className="flex  items-center text-sm mt-1.5 mr-2 bg-light-text text-white px-2   rounded-sm">
//               {file.name}
//               <MdClose
//                 className="ml-2 py-1.5 h-6 cursor-pointer"
//                 onClick={() => {
//                   setFiles(files.filter((f) => f.path !== file.path))
//                 }}
//               />
//             </div>
//           )
//         })}
//       </div>
//     </>
//   )
// }
