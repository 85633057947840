import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import Select from "react-select"

import Label from "@/components/forms/partials/Label"
import Error from "@/components/forms/partials/Error"
import { getPlaceholderValue } from "@/utils/form-utils"

export default function SelectField({
  name,
  label,
  hideLabel,
  options,
  ...rest
}) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const placeholder = getPlaceholderValue(label, rest, hideLabel)

  return (
    <div className="flex flex-col">
      <Label name={name} hidden={hideLabel} required={rest.required}>
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            {...rest}
            className={`w-full select ${errors[name] && "select--has-error"}`}
            classNamePrefix="select"
            placeholder={placeholder}
            options={options}
          />
        )}
      />
      <Error error={errors[name]} />
    </div>
  )
}
