import React, { useState } from "react"
import * as yup from "yup"

import Form from "@/components/forms/partials/Form"
import Input from "@/components/forms/partials/Input"
import Checkbox from "@/components/forms/partials/Checkbox"

import { FormTranslations } from "@/utils/form-translations"
import { getYupLocale, sendDataToNetlify } from "@/utils/form-utils"

import { setDataLayer, freshsalesTracking } from "@/utils/set-data-layer"
import redirect from "@/utils/redirect"

export default function DownloadForm({ lang, data }) {
  const [formWorking, setFormWorking] = useState(false)
  const i18n = FormTranslations[lang || "en-us"]
  const yupLocale = getYupLocale(lang)
  if (yupLocale) yup.setLocale(yupLocale)

  const schema = yup.object().shape({
    first_name: yup.string().required().label(i18n.first_name),
    last_name: yup.string().required().label(i18n.last_name),
    emails: yup.string().email().required().label(i18n.email),
    cf_company_name: yup.string().required().label(i18n.company),
    cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy:
      yup.boolean().oneOf([true], i18n.accept_terms),
  })

  let layerContent = {
    event: "formSubmission",
    formname: `Download Asset`,
    netlifyformname: data.netlify_form_name,
  }

  const onSubmit = (form) => {
    setFormWorking(true)
    sendDataToNetlify(form, data.netlify_form_name)
      .then(() => {
        setDataLayer({
          ...layerContent,
          successfull: `yes`,
        })
        try {
          freshsalesTracking(
            form.emails,
            {
              "First name": form.first_name,
              "Last name": form.last_name,
              Emails: form.emails,
              Accounts: form.cf_company_name,
              "Website Form Name": "Download",
              "Accepted TOC and DPP": JSON.stringify(form.cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy),
              "Newsletter Signup": JSON.stringify(form.cf_signup_for_our_newsletter),
            },
            {
              name: "Download Asset",
              props: {
                title: `Download Asset - ${data.netlify_form_name}`,
                email: form.emails,
              },
            }
          )
        } catch (err) {
          console.log(err)
        }
        setFormWorking(false)
        redirect(data.success_redirect)
      })
      .catch((error) => {
        console.log(error)
        setDataLayer({
          ...layerContent,
          successfull: `no`,
        })
        setFormWorking(false)
      })
  }

  return (
    <div className="max-w-sm mx-auto">
      <Form
        onSubmit={onSubmit}
        schema={schema}
        data={data}
        data-netlify="true"
        isWorking={formWorking}
        name={data.netlify_form_name}
      >
        <Input
          name="first_name"
          type="text"
          label={i18n.first_name}
          hideLabel
          required
        />
        <Input
          name="last_name"
          type="text"
          label={i18n.last_name}
          hideLabel
          required
        />
        <Input
          name="emails"
          type="email"
          label={i18n.email}
          hideLabel
          required
        />
        <Input
          name="cf_company_name"
          type="text"
          label={i18n.company}
          hideLabel
          required
        />
        <Checkbox
          name="cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy"
          required
          content={data.data_privacy_text.raw}
        />
        {data.newsletter_text.raw.length > 0 && (
          <Checkbox
            name="cf_signup_for_our_newsletter"
            required
            content={data.newsletter_text.raw}
          />
        )}
      </Form>
    </div>
  )
}
