import React from "react"
import { useFormContext } from "react-hook-form"

import Label from "@/components/forms/partials/Label"
import Error from "@/components/forms/partials/Error"
import { getPlaceholderValue } from "@/utils/form-utils"

export default function TextArea({ name, label, hideLabel, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  const inputClass = `rounded-md border-gray-300 focus:outline-none placeholder-gray-500 h-24 text-sm focus:placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary min-h ${
    errors[name] ? "border-red-600 ring-red-600 placeholder-red-400" : ""
  }`

  const placeholder = getPlaceholderValue(label, rest, hideLabel)

  return (
    <div className="flex flex-col">
      <Label name={name} hidden={hideLabel} required={rest.required}>
        {label}
      </Label>
      <textarea
        {...register(name)}
        name={name}
        id={name}
        {...rest}
        placeholder={placeholder}
        className={inputClass}
      />

      <Error error={errors[name]} />
    </div>
  )
}
