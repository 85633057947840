import React from "react"
import { useFormContext } from "react-hook-form"

import Error from "@/components/forms/partials/Error"
import TextRender from "@/components/TextRender"

export default function Checkbox({ name, children, content, ...rest }) {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  const inputClass = `rounded-sm text-primary focus:ring-primary relative top-0.5 mr-2 ${
    errors[name] ? "border-red-600 ring-red-600 " : ""
  }`

  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="flex items-start">
        <input
          type="checkbox"
          {...register(name)}
          name={name}
          id={name}
          {...rest}
          className={inputClass}
        />
        {children ? (
          <div className="checkbox text-sm">
            {children}{" "}
            {rest.required && (
              <span className="text-red-600 text-green">*</span>
            )}
          </div>
        ) : (
          <div className="checkbox text-sm">
            <TextRender
              text={content}
              serializer={(type, element, content, children, key) => {
                switch (type) {
                  case "paragraph":
                    return <>{children}</>
                  default:
                    break
                }
              }}
            />
          </div>
        )}
      </label>
      <Error error={errors[name]} />
    </div>
  )
}
