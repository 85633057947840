import { fr, it, de } from "yup-locales"

export function getPlaceholderValue(label, rest, hideLabel) {
  let placeholder = hideLabel
    ? label || rest.placeholder
    : rest.placeholder || ""

  placeholder =
    placeholder.length > 0
      ? placeholder + (rest.required ? " *" : "")
      : placeholder

  return placeholder
}

export function generateOptions(options) {
  return options?.map((item) => {
    if (typeof item === "string") {
      return {
        label: item,
        value: item,
      }
    } else {
      return item
    }
  })
}

export function convertObjectToFormData(data) {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (typeof data[k] === "string") {
      formData.append(k, data[k])
    } else if (Array.isArray(data[k])) {
      let content = data[k].reduce((prev, curr) => {
        return `${prev} ${curr.value},`
      }, "")
      formData.append(k, content)
    } else if (typeof data[k] === "boolean") {
      formData.append(k, data[k] ? "yes" : "no")
    } else {
      formData.append(k, data[k]?.value)
    }
  })
  return formData
}

export function prepareNetlifyData(data) {
  return new URLSearchParams(objectToFormData(data)).toString()
}

const objectToFormData = (obj, form, namespace) => {
  var fd = form || new FormData()
  var formKey

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]"
      } else {
        formKey = property
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        if (obj[property]?.value) {
          fd.append(formKey, obj[property].value)
        } else {
          objectToFormData(obj[property], fd, formKey)
        }
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}

export function getYupLocale(lang) {
  switch (lang) {
    case "fr-fr":
      return fr
    case "it-it":
      return it
    case "de-de":
      return de
    case "en-us":
    default:
      return false
  }
}

export function sendDataToNetlify(form, form_name) {
  return new Promise((resolve, reject) => {
    fetch("/", {
      method: "Post",
      headers: { "Content-Type": "multipart/form-data" },
      body: prepareNetlifyData({
        ...form,
        "form-name": form_name,
      }),
    })
      .then((res) => {
        console.log(res)
        if (res.ok) {
          console.log("Resolving")
          resolve(res)
        } else {
          console.log("Rejecting")
          reject(res)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
