import React from "react"

export default function SendButton({ content, isWorking }) {
  return (
    <button
      className="w-full mt-4 primary-button hover:bg-primary-dark hover:border-primary-dark transition-colors"
      disabled={isWorking}
    >
      {isWorking ? <div class="loader" /> : <>{content}</>}
    </button>
  )
}
