import React from "react"

export default function Label({ name, hidden, required, children }) {
  return (
    <label
      htmlFor={name}
      className={hidden ? "sr-only" : "font-semibold text-sm"}
    >
      {children}{" "}
      {required && <span className="text-red-600 text-green">*</span>}
    </label>
  )
}
