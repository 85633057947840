import React, { useState } from "react"
import * as yup from "yup"

import Form from "@/components/forms/partials/Form"
import Input from "@/components/forms/partials/Input"
import Select from "@/components/forms/partials/Select"
import Checkbox from "@/components/forms/partials/Checkbox"

import { setDataLayer, freshsalesTracking } from "@/utils/set-data-layer"
import redirect from "@/utils/redirect"
import { FormTranslations } from "@/utils/form-translations"
import { user_type } from "@/utils/form-lists"
import {
  generateOptions,
  getYupLocale,
  sendDataToNetlify,
} from "@/utils/form-utils"

export default function RegistrationForm({ lang, data }) {
  const i18n = FormTranslations[lang]
  const [formWorking, setFormWorking] = useState(false)
  const [formErr, setformErr] = useState([])
  const registration_types = generateOptions(user_type[lang])
  const yupLocale = getYupLocale(lang)
  if (yupLocale) yup.setLocale(yupLocale)

  const schema = yup.object().shape({
    first_name: yup.string().required().label(i18n.first_name),
    last_name: yup.string().required().label(i18n.last_name),
    emails: yup.string().email().required().label(i18n.email),
    password: yup.string().required().min(8).label(i18n.password),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], i18n.passwords_must_match),
    registration_type: yup.object().label(i18n.registration_type),
    cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy:
      yup.boolean().oneOf([true], i18n.accept_terms),
  })

  async function onSubmit(formData) {
    const registration_type = formData.registration_type.value

    let layerContent = {
      event: `Registration ${registration_type}`,
      formname: `Registration`,
    }

    let freshsalesData = {
      "First name": formData.first_name,
      "Last name": formData.last_name,
      Emails: formData.emails,
      "Registration Type": registration_type,
      "Lead Language": JSON.stringify(lang),
      "Website Form Name": "Registration",
      "Accepted TOC and DPP": "true",
      "Newsletter Signup": "true",     
    }

    setformErr([])
    setFormWorking(true)
    if (registration_type.toLowerCase() === "linguist") {
      sendDataToNetlify(formData, data.netlify_form_name)
        .then(() => {
          setDataLayer({
            ...layerContent,
            successfull: "yes",
          })
          try {
            freshsalesTracking(formData.emails, freshsalesData)
          } catch (err) {
            console.log(err)
          }
          redirect(data.linguist_registration_redirect)
        })
        .catch(() => {
          setFormWorking(false)
          setDataLayer({
            ...layerContent,
            successfull: "no",
          })
        })
    } else {
      const consent =
        formData.cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy
      fetch(`${process.env.GATSBY_GC_API_ENDPOINT}/auth/registration`, {
        body: JSON.stringify({
          email: formData.emails,
          first_name: formData.first_name,
          last_name: formData.last_name,
          password: formData.password,
          termsAndConditions: consent,
          privacyPolicy: consent,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((res) => {
          if (res.ok) {
            setDataLayer({
              ...layerContent,
              successfull: "yes",
            })

            try {
              freshsalesTracking(formData.emails, freshsalesData)
            } catch (err) {
              console.log(err)
            }

            fetch(`${process.env.GATSBY_GC_API_ENDPOINT}/auth/login`, {
              body: JSON.stringify({
                grant_type: "password",
                client_id: `${process.env.CLIENT_ID}`,
                client_secret: `${process.env.CLIENT_SECRET}`,
                username: formData.emails,
                password: formData.password,
              }),
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
            })
              .then((res) => {
                console.log(res)
                document.cookie = `accessToken=${res.access_token};domain=.greatcontent.com;path=/`
                document.cookie = `refreshToken=${res.refresh_token};domain=.greatcontent.com;path=/`
                setTimeout(() => {
                  window.open(
                    `${process.env.GATSBY_CLIENT_ENDPOINT}/login`,
                    "_self"
                  )
                }, 5000)
                redirect(data.client_registration_redirect)
                return res.json()
              })
              .then((res) => {
                console.log("Login", res)
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                setFormWorking(false)
              })
          } else {
            return res.json()
          }
        })
        .then((res) => {
          setformErr(Object.values(res.error).flat())
          setFormWorking(false)
          setDataLayer({
            ...layerContent,
            successfull: "no",
          })
        })
        .catch((error) => {
          console.log("Error", error)
          setFormWorking(false)
          setDataLayer({
            ...layerContent,
            successfull: "no",
          })
        })
    }
  }
  return (
    <div>
      {formErr.length > 0 && (
        <div className="bg-red-100 p-4 text-red-600 mb-3 rounded-md">
          {formErr.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        schema={schema}
        data={data}
        data-netlify="true"
        name={data.netlify_form_name}
        isWorking={formWorking}
      >
        <Input
          name="first_name"
          type="text"
          label={i18n.first_name}
          hideLabel
          required
        />
        <Input
          name="last_name"
          type="text"
          label={i18n.last_name}
          hideLabel
          required
        />
        <Input
          name="emails"
          type="email"
          label={i18n.email}
          autocomplete="email"
          hideLabel
          required
        />
        <Input
          name="password"
          type="password"
          autocomplete="new-password"
          label={i18n.password}
          hideLabel
          required
        />
        <Input
          name="password_confirmation"
          type="password"
          autocomplete="new-password"
          label={i18n.password_confirmation}
          hideLabel
          required
        />
        <Select
          name="registration_type"
          label={i18n.registration_type}
          required
          options={registration_types}
          hideLabel
        />
        <div className="mt-2 grid grid-cols-1 gap-3">
          <Checkbox
            name="cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy"
            required
            content={data.data_privacy_text.raw}
          />
          {data.newsletter_text.raw.length > 0 && (
            <Checkbox
              name="cf_signup_for_our_newsletter"
              required
              content={data.newsletter_text.raw}
            />
          )}
        </div>
      </Form>
    </div>
  )
}
