export const FormTranslations = {
  "en-us": {
    companyinfo: "Company Information",
    languagestopics: "Languages and topics",
    projectdescription: "Project descriptions",
    full_name: "Full Name",
    first_name: "First Name",
    last_name: "Last Name",
    company: "Company",
    email: "Email",
    phone_number: "Phone Number",
    company_size: "Company Size",
    country_of_residence: "Country of Residence",
    service: "Service",
    text_type: "Type of text",
    topic: "Topic of text",
    source_lang: "Source Language",
    target_langs: "Target Languages",
    lang: "Language",
    project_scope: "Project Scope",
    project_description: "Project Description",
    project_files: "Upload files (optional)",
    file_content: "Drop your translation files or click to upload.",
    password: "Password",
    password_confirmation: "Confirm Password",
    passwords_must_match: "Passwords must match",
    accept_terms: "You need to accept terms of service",
    registration_type: "Registration Type",
  },
  "fr-fr": {
    companyinfo: "Company Information",
    languagestopics: "Languages and topics",
    projectdescription: "Project descriptions",
    full_name: "Full Name",
    first_name: "First Name",
    last_name: "Last Name",
    company: "Company",
    email: "Email",
    phone_number: "Phone Number",
    company_size: "Company Size",
    country_of_residence: "Country of Residence",
    service: "Service",
    text_type: "Type of text",
    topic: "Topic of text",
    source_lang: "Source Language",
    target_langs: "Target Languages",
    lang: "Language",
    project_scope: "Project Scope",
    project_description: "Project Description",
    project_files: "Upload files (optional)",
    file_content: "Drop your translation files or click to upload.",
    password: "Password",
    password_confirmation: "Confirm Password",
    passwords_must_match: "Passwords must match",
    accept_terms: "You need to accept terms of service",
    registration_type: "Registration Type",
  },
  "de-de": {
    companyinfo: "Firma",
    languagestopics: "Sprache und Themen",
    projectdescription: "Projektbeschreibung",
    full_name: "Name",
    first_name: "Vorname",
    last_name: "Nachnamee",
    company: "Firma",
    email: "Email",
    phone_number: "Telefon",
    company_size: "Firmengröße",
    country_of_residence: "Unternehmenssitz",
    service: "Service",
    text_type: "Texttyp",
    topic: "Textthema",
    source_lang: "Ausgangssprache",
    target_langs: "Zielsprache",
    lang: "Sprache",
    project_scope: "Projektart",
    project_description: "Projekt Beschreibung",
    project_files: "Datei-Upload (optional)",
    file_content: "Datei hier herziehen oder klicken",
    password: "Passwort",
    password_confirmation: "Passwort (Wdh.)",
    passwords_must_match: "Passwörter müssen übereinstimmen",
    accept_terms: "Sie müssen die AGB und Datenschutzerklärung akzeptieren",
    registration_type: "Registrierungs-Typ",
  },
  "it-it": {
    companyinfo: "Company Information",
    languagestopics: "Languages and topics",
    projectdescription: "Project descriptions",
    full_name: "Full Name",
    first_name: "First Name",
    last_name: "Last Name",
    company: "Company",
    email: "Email",
    phone_number: "Phone Number",
    company_size: "Company Size",
    country_of_residence: "Country of Residence",
    service: "Service",
    text_type: "Type of text",
    topic: "Topic of text",
    source_lang: "Source Language",
    target_langs: "Target Languages",
    lang: "Language",
    project_scope: "Project Scope",
    project_description: "Project Description",
    project_files: "Upload files (optional)",
    file_content: "Drop your translation files or click to upload.",
    password: "Password",
    password_confirmation: "Confirm Password",
    passwords_must_match: "Passwords must match",
    accept_terms: "You need to accept terms of service",
    registration_type: "Registration Type",
  },
}
