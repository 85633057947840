import React from "react"

export default function Error({ error }) {
  return (
    <>
      {error && (
        <div className="text-xs px-0.5 pt-1 text-red-500">{error.message}</div>
      )}
    </>
  )
}
