import React, { useState, useEffect } from "react"
import SlideToggle from "react-slide-toggle"
import Chevron from "@/icons/Chevron"
import { useFormContext } from "react-hook-form"

export default function FormToggle({ children, active, toggle, id, title }) {
  const [isActive, setIsActive] = useState(false)
  const [collapsibleClass, setCollapsible] = useState("w-full")

  useEffect(() => {
    let isActive = active === id
    setIsActive(isActive)
    if (isActive) {
      setTimeout(() => {
        setCollapsible("w-full")
      }, 300)
    } else {
      setCollapsible("w-full overflow-hidden")
    }
  }, [active])
  const {
    formState: { errors },
  } = useFormContext()
  const fields = React.Children.map(children, (child) => {
    return child.props.name
  })

  const hasErrors = Object.keys(errors).some((item) => fields.includes(item))

  const faqClass = `min-h-28 border cursor-pointer relative rounded-md self-start flex flex-col flex flex-col justify-center items-start ${
    isActive ? "border-primary" : "border-grey-200"
  } ${hasErrors ? "border-red-500" : ""}`

  return (
    <SlideToggle
      collapsed={!isActive}
      collapseEvent={!isActive && Date.now()}
      expandEvent={isActive && Date.now()}
      noOverflowHidden
    >
      {({ setCollapsibleElement }) => (
        <div className={faqClass.toString()}>
          <div
            className="relative w-full flex justify-between items-center cursor-pointer px-5 py-4"
            onClick={() => toggle(isActive ? null : id)}
          >
            <div className="text-base text-black transition-colors duration-300 ease-in-out rotate-180">
              {title}
            </div>
            <div
              className={`transform transition-transform ${
                !isActive && "rotate-180"
              }`}
            >
              <Chevron />
            </div>
          </div>
          <div
            ref={setCollapsibleElement}
            className={collapsibleClass.toString()}
          >
            <div className="px-5 pb-4">
              <div className="grid grid-cols-1 gap-4 border-t border-gray-200 pt-4">
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </SlideToggle>
  )
}
