export function setDataLayer(val) {
  if (isWindowDefined) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(val)
  }
}

function isWindowDefined() {
  return typeof window !== "undefined"
}

export function freshsalesTracking(identifier, contact, event) {
  console.log("Setting identity..")
  window.fwcrm.identify(identifier, contact)
  if (event?.name) {
    console.log("Setting event..")
    window.fwcrm.trackCustomEvent(event.name, event.props)
  }
}
