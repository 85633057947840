import React, { useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import FormToggle from "@/components/forms/partials/FormToggle"
import Input from "@/components/forms/partials/Input"
import TextArea from "@/components/forms/partials/TextArea"
import Select from "@/components/forms/partials/Select"
import Checkbox from "@/components/forms/partials/Checkbox"
import FileField from "@/components/forms/partials/FileField"
import SendButton from "@/components/forms/partials/SendButton"

import { FieldsData } from "@/utils/form-lists"
import { FormTranslations } from "@/utils/form-translations"
import {
  generateOptions,
  getYupLocale,
  sendDataToNetlify,
} from "@/utils/form-utils"

import { setDataLayer, freshsalesTracking } from "@/utils/set-data-layer"
import redirect from "@/utils/redirect"

export default function QuoteForm({ lang, data }) {
  const [activeTab, setActiveTab] = useState("companyinfo")
  const [formWorking, setFormWorking] = useState(false)

  const i18n = FormTranslations[lang]
  const lists = FieldsData[lang]
  const yupLocale = getYupLocale(lang)
  if (yupLocale) yup.setLocale(yupLocale)

  const schema = yup.object().shape({
    first_name: yup.string().required().label(i18n.first_name),
    last_name: yup.string().required().label(i18n.last_name),
    emails: yup.string().email().required().label(i18n.email),
    cf_company_name: yup.string().required().label(i18n.company),
    work_number: yup.string().required().label(i18n.phone_number),
    cf_company_size: yup.object().label(i18n.company_size).required(),
    cf_country_of_residence: yup
      .object()
      .label(i18n.country_of_residence)
      .required(),
    cf_service: yup.object().label(i18n.service).required(),
    cf_type_of_text: yup.object().label(i18n.text_type).required(),
    cf_topic_of_topic: yup.object().label(i18n.topic).required(),
    cf_project_scope: yup.object().label(i18n.project_scope).required(),
    cf_project_description: yup
      .string()
      .min(10)
      .label(i18n.project_description)
      .required(),
    cf_languages: yup
      .object()
      .label(i18n.lang)
      .when("cf_service", {
        is: (val) => val?.value !== "translation",
        then: yup.object().required(),
        otherwise: yup.object().optional(),
      }),
    cf_target_languages: yup
      .array()
      .label(i18n.target_langs)
      .when("cf_service", {
        is: (val) => val?.value === "translation",
        then: yup.array().of(yup.object()).required(),
        otherwise: yup.array().of(yup.object()).optional(),
      }),
    cf_source_language: yup
      .object()
      .label(i18n.source_lang)
      .when("cf_service", {
        is: (val) => val?.value === "translation",
        then: yup.object().required(),
        otherwise: yup.object().optional(),
      }),
    cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy:
      yup.boolean().oneOf([true], i18n.accept_terms),
    cf_signup_for_our_newsletter: yup.boolean(),
  })

  const methods = useForm({ resolver: yupResolver(schema) })
  const selectedService = methods.watch("cf_service")
  const isTranslationSelected = selectedService?.value === "translation"

  const onSubmit = (form) => {
    let layerContent = {
      event: "formSubmission",
      formname: `${form.cf_service.label} Custom Quote`,
      service_type: form.cf_service.label,
    }
    setFormWorking(true)
    sendDataToNetlify(form, data.netlify_form_name)
      .then(() => {
        setDataLayer({
          ...layerContent,
          successfull: "yes",
        })
        console.log("data send")
        try {
          freshsalesTracking(form.emails, {
            "First name": form.first_name,
            "Last name": form.last_name,
            Emails: form.emails,
            "Company Name": form.cf_company_name,
            Work: form.work_number,
            "Company Size": form.cf_company_size?.value || "-",
            "Country of residence": form.cf_country_of_residence?.value || "-",
            "Service Type": form.cf_service?.value || "-",
            "Topic of topic": form.cf_topic_of_topic?.value || "-",
            "Type of text": form.cf_type_of_text?.value || "-",
            "Languages": form.cf_languages?.value || "-",
            "Source Language": form.cf_source_language?.value || "-",
            "Target Language": JSON.stringify(form.cf_target_languages),
            "Project Scope": form.cf_project_scope?.value || "-",
            "Project Description": form.cf_project_description,
            "Lead Language": JSON.stringify(lang),
            "Website Form Name": "Quote",
            "Accepted TOC and DPP": JSON.stringify(form.cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy),
            "Newsletter Signup": JSON.stringify(form.cf_signup_for_our_newsletter),
            
          })
        } catch (err) {
          console.log(err)
        }
        setFormWorking(false)
        console.log("Redirecting...")
        redirect(data.success_redirect)
      })
      .catch(() => {
        console.log("data not send")
        setDataLayer({
          ...layerContent,
          successfull: "no",
        })
        setFormWorking(false)
      })
  }

  return (
    <div className="max-w-sm mx-auto">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          data-netlify="true"
          name={data.netlify_form_name}
        >
          <div className="grid grid-cols-1 gap-2">
            <FormToggle
              title={`1. ${i18n.companyinfo}`}
              id="companyinfo"
              active={activeTab}
              toggle={setActiveTab}
            >
              <Input
                name="first_name"
                type="text"
                label={i18n.first_name}
                hideLabel
                required
              />
              <Input
                name="last_name"
                type="text"
                label={i18n.last_name}
                hideLabel
                required
              />
              <Input
                name="emails"
                type="email"
                label={i18n.email}
                hideLabel
                required
              />
              <Input
                name="cf_company_name"
                type="text"
                label={i18n.company}
                hideLabel
                required
              />
              <Input
                name="work_number"
                type="tel"
                label={i18n.phone_number}
                hideLabel
                required
              />
              <Select
                name="cf_company_size"
                label={i18n.company_size}
                isSearchable={false}
                required
                options={generateOptions(lists.company_size)}
                hideLabel
              />
              <Select
                name="cf_country_of_residence"
                label={i18n.country_of_residence}
                required
                options={generateOptions(lists.countries)}
                hideLabel
              />
            </FormToggle>
            <FormToggle
              title={`2. ${i18n.languagestopics}`}
              id="languagestopics"
              active={activeTab}
              toggle={setActiveTab}
            >
              <Select
                name="cf_service"
                label={i18n.service}
                required
                options={generateOptions(lists.services)}
                hideLabel
              />
              <Select
                name="cf_type_of_text"
                label={i18n.text_type}
                required
                options={generateOptions(lists.text_types)}
                hideLabel
              />
              <Select
                name="cf_topic_of_topic"
                label={i18n.topic}
                required
                options={generateOptions(lists.topics)}
                hideLabel
              />

              <div
                className={
                  !isTranslationSelected ? "hidden" : "grid grid-cols-1 gap-4"
                }
              >
                <Select
                  name="cf_source_language"
                  label={i18n.source_lang}
                  required
                  options={generateOptions(lists.lang)}
                  hideLabel
                />
                <Select
                  name="cf_target_languages"
                  label={i18n.target_langs}
                  required
                  isMulti
                  options={generateOptions(lists.lang)}
                  hideLabel
                />
              </div>

              <div
                className={
                  isTranslationSelected ? "hidden" : "grid grid-cols-1 gap-4"
                }
              >
                <Select
                  name="cf_languages"
                  label={i18n.lang}
                  required
                  options={generateOptions(lists.lang)}
                  hideLabel
                />
              </div>
            </FormToggle>
            <FormToggle
              title={`3. ${i18n.projectdescription}`}
              id="projectdescription"
              active={activeTab}
              toggle={setActiveTab}
            >
              <Select
                name="cf_project_scope"
                label={i18n.project_scope}
                required
                options={generateOptions(lists.project_scope)}
                hideLabel
              />
              <TextArea
                name="cf_project_description"
                label={i18n.project_description}
                hideLabel
                required
              />
              <div className={!isTranslationSelected && "hidden"}>
                <FileField
                  name="cf_translation_files_url"
                  label={i18n.project_files}
                  description={i18n.file_content}
                  hideLabel
                />
              </div>
            </FormToggle>
            <div className="mt-2 grid grid-cols-1 gap-3">
              <Checkbox
                name="cf_i_have_read_and_accepted_the_terms_and_conditions_and_data_privacy_policy"
                required
                content={data.data_privacy_text.raw}
              />
              {data.newsletter_text.raw.length > 0 && (
                <Checkbox
                  name="cf_signup_for_our_newsletter"
                  required
                  content={data.newsletter_text.raw}
                />
              )}
            </div>
            <SendButton
              content={data.send_button_label}
              isWorking={formWorking}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
