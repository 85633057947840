const languages_list = [
  "English - United States",
  "English - United Kingdom",
  "Dutch - The Netherlands",
  "Spanish - Spain",
  "French - France",
  "Polish - Poland",
  "Italian - Italy",
  "Portuguese - Portugal",
  "Russian - Russia",
  "German - Germany",
  "Swedish - Sweden",
  "Portuguese - Brazil",
  "Danish - Denmark",
  "Finnish - Finland",
  "Norwegian - Norway",
  "Bosnian - Bosnia",
  "Czech - Czech Republic",
  "German - Austria",
  "German - Switzerland",
  "English - Australia",
  "English - Canada",
  "Spanish - Argentina",
  "Spanish - Chile",
  "Spanish - Mexico",
  "French - Switzerland",
  "Croatian - Croatia",
  "Hungarian - Hungaria",
  "Moldavian - Moldavia",
  "Dutch - Flemisch",
  "Romanian - Romania",
  "Slovakian - Slovakia",
  "Slovenian - Slovenia",
  "Serbian - Serbian",
  "Ukrainian - Ukraine",
]

const industry_list = [
  "Banking / Finance / Insurance",
  "Consumer Electronics",
  "Dating",
  "DIY / Garden / Pets",
  "Education",
  "Fashion / Beauty",
  "Food / Drinks / Nutrition ",
  "Furniture / Household",
  "Gaming / Gambling",
  "Healthcare / Pharma",
  "Legal",
  "Manufacturing / Industry",
  "Media / Entertainment",
  "Mobility / Automotive",
  "Online / Marketing (General)",
  "Real Estate",
  "Software / Technology",
  "Sports / Outdoors",
  "Travel",
]

// Don't change values, labels can be changed and reordered
const user_type = {
  "en-us": [
    { label: "Client (I want to buy texts or translations)", value: "client" },
    { label: "Linguist (I want to write or translate)", value: "linguist" },
  ],
  "fr-fr": [
    { label: "Client (Je veux acheter des textes)", value: "client" },
    { label: "Linguist (Je veux rédiger ou traduire)", value: "linguist" },
  ],

  "de-de": [
    { label: "Client (Ich möchte Texte oder Übersetzungen bestellen)", value: "client" },
    { label: "Linguist (Ich möchte schreiben oder übersetzen)", value: "linguist" },
  ],
  "it-it": [
    { label: "Client (Voglio comprare testi o traduzioni)", value: "client" },
    { label: "Linguist (Voglio scrivere o tradurre)", value: "linguist" },
  ],
}

// Don't change values, labels can be changed and reordered
const services_list = {
  "en-us": [
    { label: "Copywriting", value: "copywriting" },
    { label: "Translation", value: "translation" },
    { label: "AI Textgeneration", value: "ai-textgeneration" },
  ],
  "fr-fr": [
    { label: "Copywriting", value: "copywriting" },
    { label: "Translation", value: "translation" },
    { label: "AI Textgeneration", value: "ai-textgeneration" },
  ],
  "de-de": [
    { label: "Copywriting", value: "copywriting" },
    { label: "Translation", value: "translation" },
    { label: "AI Textgeneration", value: "ai-textgeneration" },
  ],
  "it-it": [
    { label: "Copywriting", value: "copywriting" },
    { label: "Translation", value: "translation" },
    { label: "AI Textgeneration", value: "ai-textgeneration" },
  ],
}
const text_types_list = {
  "en-us": [
    "Marketing articles",
    "Specialized/Professional articles",
    "Blog posts",
    "Social media posts",
    "Brand descriptions",
    "Category descriptions",
    "Destination descriptions",
    "Train, bus, flight descriptions",
    "E-Mail-Newsletters",
    "Whitepapers & eBooks",
    "How to articles",
    "Glossary & Lexicon articles",
    "Landing-Pages",
    "Press releases",
    "Product descriptions",
    "Amazon brand content",
    "Buying guides",
  ],
  "fr-fr": [
    "Articles Marketing",
    "Articles spécialisés / professionnels",
    "Blogposts",
    "Social Media Posts",
    "Descriptions de marque",
    "Descriptions de catégorie",
    "Descriptions de destination",
    "Descriptions de lignes de train, bus, avion",
    "Newsletter",
    "Livres blancs & E-Books",
    "Guide pratique",
    "Glossaires & lexiques",
    "Landing page",
    "Communiqués de presse",
    "Descriptions de produit",
    "Contenu Amazon",
    "Guide d'achat",
  ],

  "de-de": [
    "Werbetexte",
    "Fachbeiträge",
    "Blogposts",
    "Social Media Posts",
    "Markenbeschreibungen",
    "Kategorietexte",
    "Destinationsbeschreibungen",
    "Routenbeschreibungen (Bahn, Bus, Flugzeug)",
    "E-Mail-Newsletter",
    "Whitepaper & E-Books",
    "Howtos",
    "Glossar- und Lexikonartikel",
    "Landingpages",
    "Pressemitteilungen",
    "Produktbeschreibungen",
    "Amazon-Content",
    "Ratgeber",
  ],
  "it-it": [
    "Marketing",
    "Testi professionali/specialistici",
    "Post per blog",
    "Post per social media",
    "Descrizione di brand",
    "Descrizione di categoria",
    "Descrizione di meta turistica",
    "Descrizione di tratte in bus, aereo o treno",
    "Newsletter",
    "eBooks e Whitepaper",
    "Guide pratiche",
    "Glossario e Lessico",
    "Landing page",
    "Comunicati stampa",
    "Descrizione di prodotto",
    "Contenuti per Amazon",
    "Guide all'acquisto",
  ],
}

const countries_list = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo {Democratic Rep}",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland {Republic}",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, {Burma}",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
]

const company_size_list = [
  "1-2 Employees",
  "< 5 Employees",
  "< 10 Employees",
  "< 50 Employees",
  "< 100 Employees",
  "> 100 Employees",
]
const project_scope_list = [
  "One off project",
  "<5 texts per month",
  "<20 texts per month",
  "<50 texts per month",
  ">50 texts per month",
]

const FieldsData = {
  "en-us": {
    lang: languages_list,
    topics: industry_list,
    countries: countries_list,
    company_size: company_size_list,
    project_scope: project_scope_list,
    users: user_type["en-us"],
    text_types: text_types_list["en-us"],
    services: services_list["en-us"],
  },
  "fr-fr": {
    lang: languages_list,
    topics: industry_list,
    countries: countries_list,
    company_size: company_size_list,
    project_scope: project_scope_list,
    users: user_type["fr-fr"],
    text_types: text_types_list["fr-fr"],
    services: services_list["fr-fr"],
  },
  "de-de": {
    lang: languages_list,
    topics: industry_list,
    countries: countries_list,
    company_size: company_size_list,
    project_scope: project_scope_list,
    users: user_type["de-de"],
    text_types: text_types_list["de-de"],
    services: services_list["de-de"],
  },
  "it-it": {
    lang: languages_list,
    topics: industry_list,
    countries: countries_list,
    company_size: company_size_list,
    project_scope: project_scope_list,
    users: user_type["it-it"],
    text_types: text_types_list["it-it"],
    services: services_list["it-it"],
  },
}

export {
  FieldsData,
  countries_list,
  languages_list,
  industry_list,
  text_types_list,
  company_size_list,
  project_scope_list,
  user_type,
  services_list,
}
